import Header from "./components/Header";
import Loadscreen from "./components/Loadscreen";
import Mainpage from "./components/Mainpage";
import React from 'react';
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter } from 'react-router-dom';

import { Link, Route, Routes, Navigate } from "react-router-dom";
import ProjectDetail from "./components/ProjectDetail";

function App() {

  return (
    <HashRouter basename="/">

      <div className="App">
        {<Loadscreen />}
        <Header />

        <Routes>

          <Route exact path="/" element={<Mainpage />}>
          </Route>

          <Route exact path="/projecten/" element={<Projects />}>
          </Route>

          <Route exact path="/projecten/:projectId" element={<ProjectDetail />}>
          </Route>

          <Route exact path="/contact/" element={<Contact />}>
          </Route>

          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />

        </Routes>

      </div>
    </HashRouter>
  );
}

export default App;
