import React from 'react'
import { useParams } from "react-router-dom"
import Footer from './Footer'
import Slider from 'react-slick'
import image1 from "../images/school.png"
import { request } from 'graphql-request';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom"


function ProjectDetail() {
    const { projectId } = useParams()

    let settings = {
        dots: true,
        customPaging: function (slider, i) {
            // this example would render "tabs" with titles
            return <span className="dot"></span>;
        },
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        accessibility: false,
        rows: 1



    };

    const [currentProject, setCurrentProject] = React.useState()

    React.useEffect(() => {
        const fetchCurrentProject = async () => {
            const { projects } = await request(
                'https://api-eu-west-2.graphcms.com/v2/ckzzx0bo98n2501z86us8aefi/master',
                `
                {
                    projects(where: {nummer: ${projectId}}) {
                        projectnaam
                        nummer
                        text
                        fotos {
                            url
                            id
                        }
                        
                        oppervlakte
                        jaar
                        energie
                        projectStatus
                    }
                }
                `
            );

            setCurrentProject(projects[0]);
        };

        fetchCurrentProject();
    }, []);

    //console.log(currentProject)

    let mappedImages = []

    if (currentProject) {
        mappedImages = currentProject.fotos.map((foto, idx) => {
            return (<div key={foto.id} className='projectDetail__slide'>
                <img alt='' src={foto.url} />
            </div>)
        })
    }


    return (
        <div className='projectDetailPage'>
            {currentProject && <div className='projectDetail'>
                <Link to="/projecten/" className='projectDetail__back--link'>
                    <p className='projectDetail__back'>Terug</p>
                </Link>
                <div className='projectDetail__sliderContainer'>
                    <Slider {...settings}>
                        {mappedImages}
                    </Slider>
                </div>
                <div>
                    <h1 className='projectDetail__title'>{projectId}_ {currentProject.projectnaam}
                    </h1>

                    <p className='projectDetail__text'>{currentProject.text}</p>

                    <p className='projectDetail__status'>Status: {currentProject.projectStatus.replace("_", " ")}</p>
                    <p className='projectDetail__jaar'>Jaar: {currentProject.jaar}</p>
                    <p className='projectDetail__energie'>Energie: {currentProject.energie}</p>
                    <p className='projectDetail__opp'>Oppervlakte: {currentProject.oppervlakte}m<sup>2</sup></p>

                </div>

            </div>}
            <Footer />
        </div>

    )
}

export default ProjectDetail